import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import type { ModuleActionItem } from "@/core/model/ModuleActionItem";

/**
 * @param activeDateRange
 * @param activeFilterMutual
 * @param buttons
 */
export const setCurrentPageActions = (
  activeDateRange: boolean,
  activeFilterMutual: boolean,
  buttons: Array<ModuleActionItem>,
  dateRangeValue?: Array<string>,
  filterMutualValue?: string
): void => {
  store.dispatch(Actions.TA_CONFIGURE_ACTION, {
    buttons: buttons,
    date_range_active: activeDateRange,
    date_range_value: dateRangeValue ? dateRangeValue : [],
    filter_mutual_active: activeFilterMutual,
    filter_mutual_value: filterMutualValue ? filterMutualValue : "",
  });
};
